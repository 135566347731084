import React from "react"
import {
    Avatar,
    AvatarFallback,
    AvatarImage,
  } from "../components/ui/avatar"
import { Button } from "../components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu"
import { Link, useNavigate } from "react-router-dom";
import { signOut } from "supertokens-auth-react/recipe/thirdpartypasswordless";
import { useSessionContext } from "supertokens-auth-react/recipe/session"

export function UserNav() {
    const userSession = useSessionContext();
    console.log("user session? ", userSession)
    const navigate = useNavigate();

    const handleLogout = React.useCallback(async () => {
      console.log("logging out");
      try{
        await signOut();
      }catch(e){
        console.error("Error logging out", e);
      } finally {
        navigate("/");
      }
      
    },[navigate]);

    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="relative h-8 w-8 rounded-full">
            <Avatar className="h-9 w-9">
              <AvatarImage src="/logo192.jpg" alt="@videogum" />
              <AvatarFallback>VG</AvatarFallback>
            </Avatar>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56" align="end" forceMount>
          <DropdownMenuLabel className="font-normal">
            <div className="flex flex-col space-y-1">
              <p className="text-sm font-medium leading-none">@username</p>
              <p className="text-xs leading-none text-muted-foreground">
                {/* {userInfo.email ? userInfo.email : "--"} */}
                {/* {userInfo.session ? " - " + userInfo.session.userId : ""} */}
              </p>
            </div>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <Link to="/profile">
              <DropdownMenuItem>Profile</DropdownMenuItem>
            </Link>
            {/* <DropdownMenuItem>
              Billing
              <DropdownMenuShortcut>⌘B</DropdownMenuShortcut>
            </DropdownMenuItem>
            <DropdownMenuItem>
              Settings
              <DropdownMenuShortcut>⌘S</DropdownMenuShortcut>
            </DropdownMenuItem>
            <DropdownMenuItem>New Team</DropdownMenuItem> */}
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={()=>{ handleLogout(); }}>
            Log out
            <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    )
  }