import React from "react"
import { Copy, Edit, MoreVertical, TrashIcon } from "lucide-react"
import { Row } from "@tanstack/react-table"

import { Button } from "../components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  // DropdownMenuRadioGroup,
  // DropdownMenuRadioItem,
  // DropdownMenuSeparator,
  // DropdownMenuShortcut,
  // DropdownMenuSub,
  // DropdownMenuSubContent,
  // DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu"

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  // AlertDialogTrigger,
} from "../components/ui/alert-dialog"


// import { labels } from "../data/data"
// import { taskSchema } from "../data/schema"

interface DataTableRowActionsProps<TData> {
  row: Row<TData>
  onSelect?: (row: Row<TData>, selection: string) => void
}

export function DataTableRowActions<TData>({
  row,
  onSelect
}: DataTableRowActionsProps<TData>) {
  //const task = taskSchema.parse(row.original)

  const menuItems = [
    {
      id: "edit",
      label: "Edit",
      requiresConfirmation: false,
      icon: <Edit size={16} />
    },
    {
      id: "copy",
      label: "Make a copy",
      requiresConfirmation: false,
      icon: <Copy size={16} />
    },
    {
      id: "delete",
      label: "Delete",
      requiresConfirmation: true,
      icon: <TrashIcon size={16} />
    }
  ]
  const [showDialog, setShowDialog] = React.useState(false);
  const [selected, setSelected] = React.useState<string | undefined>(undefined);

  return (<> <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
        >
          <MoreVertical className="h-4 w-4" />
          <span className="sr-only">Open menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px]">
        {
          menuItems.map((item, index) => (
            
            <DropdownMenuItem
              key={`menu_item_${index}`}      
                onSelect={()=>{
                  if(item.requiresConfirmation){
                    setShowDialog(true);
                    setSelected(item.id);
                  } else {
                    onSelect && onSelect(row, item.id);
                  }
                }}
                className="flex items-center justify-between"
              >
                {item.label}
                {item.icon}
            </DropdownMenuItem>
            
          ))
        }
        
        {/* <DropdownMenuItem>Favorite</DropdownMenuItem> */}
        {/* <DropdownMenuSeparator />
        <DropdownMenuSub>
          <DropdownMenuSubTrigger>Labels</DropdownMenuSubTrigger>
          <DropdownMenuSubContent>
            <DropdownMenuRadioGroup value={task.label}>
              {labels.map((label) => (
                <DropdownMenuRadioItem key={label.value} value={label.value}>
                  {label.label}
                </DropdownMenuRadioItem>
              ))}
            </DropdownMenuRadioGroup>
          </DropdownMenuSubContent>
        </DropdownMenuSub> */}
        
        
          {/* <DropdownMenuShortcut>⌘⌫</DropdownMenuShortcut> */}
        
      </DropdownMenuContent>
    </DropdownMenu>
      <AlertDialog open={showDialog} >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={()=>{ setShowDialog(false); setSelected(undefined); }} >Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={()=>{
              onSelect && selected && onSelect(row, selected);
              setShowDialog(false);
              setSelected(undefined);
            }} >Continue</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}