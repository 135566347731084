
import {Loader2} from 'lucide-react';
import React from 'react';

const Icons = {
    spinner: Loader2,
};

export interface LoadingProps {
    className?: string;
}

export const Loading: React.FC<LoadingProps> = ({className}) => {
    return <div className="flex flex-row items-center justify-between py-4">
        <Icons.spinner className={["animate-spin", className ? className : "text-orange-400 h-16 w-16"].join(" ")} />
    </div>
}