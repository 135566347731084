import React from 'react';
import ReactDOM from 'react-dom/client';


import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
//import ThirdPartyEmailPassword, {Google, Facebook, Apple} from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import Session from "supertokens-auth-react/recipe/session";
import ThirdPartyPasswordless, {Google,Apple, Facebook} from "supertokens-auth-react/recipe/thirdpartypasswordless";


import './styles/globals.css';

// import reportWebVitals from './reportWebVitals';

import { ThemeProvider } from './components/theme-provider';

import AppRouter from './router';

const getEnv = ():string => {
  const splt = window.location.host.split('.');
  if(window.location.hostname.includes('localhost')){return 'dev-';}
  return splt.length > 2 ? `${splt[0]}-` : '';
}

SuperTokens.init({
  appInfo: {
      // learn more about this on https://supertokens.com/docs/thirdpartyemailpassword/appinfo
      appName: "Videogum",
      apiDomain: `https://${getEnv()}api.videogum.app`,
      websiteDomain: window.location.host, // "https://videogum.app",
      apiBasePath: "/auth",
      websiteBasePath: "/auth",
  },
  clientType: "web-and-android",
  recipeList: [
      ThirdPartyPasswordless.init({
        contactMethod: "EMAIL_OR_PHONE",
        signInUpFeature: {
          termsOfServiceLink: "https://videogum.ai/terms-of-service",
          privacyPolicyLink: "https://videogum.ai/privacy-policy",
          providers: [
            Google.init(),
            // Facebook.init(),
            Apple.init(),
          ]
        }
      }),
      // ThirdPartyEmailPassword.init({
      //     signInAndUpFeature: {
      //         providers: [
      //             Google.init(),
      //             Facebook.init(),
      //             Apple.init(),
      //         ]
      //     }
      // }),
      Session.init({
        //cookie is preferred method
        tokenTransferMethod: 'cookie', // or header
        autoAddCredentials: true,
      })
  ]
});


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    {/* <RouterProvider router={router} /> */}
    <ThemeProvider
      attribute='class'
      defaultTheme='system'
      enableSystem
      disableTransitionOnChange
    >
      <SuperTokensWrapper>
          <AppRouter />
      </SuperTokensWrapper>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
