import React from 'react';
// import { Link } from 'react-router-dom';
import { cn } from '../../lib/utils';
import { Page } from '../page';
import { Link } from 'react-router-dom';
import { Button } from '../../components/ui/button';

export const HomePage = () => {

    return (
        <Page>
            <section id="hero">
                <div className={cn("mx-auto max-w-xl md:text-center")}>
                    {/* <div className={cn("flex justify-center")}>
                        <img className='rounded-full' width={512} src="/logo512.jpg" alt="logo" />
                    </div>
                    <h1 className={cn("mt-4 text-4xl tracking-tight")}>Videogum</h1> */}
                    <div className="flex flex-column gap-6 items-center border rounded-lg p-6 text-center justify-center">
                        <div className=''>
                            <img className='rounded-full' width={256} src="/logo512.jpg" alt="logo" />
                            <h3 className="text-bold text-4xl">Videogum</h3>
                        </div>
                    </div>
                </div>
                
                {/* <div className={cn('flex row justify-center align-middle w-full')}>
                    <div>
                        <div className={cn('flex  justify-center align-middle h-full')}>
                            
                        </div>
                    </div>
                </div> */}
            </section>
            
            
            <section id="login">
                <div className='flex flex-col items-center justify-center py-8'>
                    <Link to={`/auth/?redirectToPath=%2Fupload`}>
                        <Button size={'lg'}>Login</Button>
                    </Link>
                </div>
            </section>

            <section id="app-links">
                <div className="flex flex-col items-center justify-center py-8">
                    <div className="flex gap-2">
                        <a href="https://apps.apple.com/us/app/videogum/id6467995231" target="_blank" rel="noopener noreferrer nofollow" className="link link-focus">
                            <img className="h-custom" src="/app-store.svg" alt="Videogum on App Store"/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=app.videogum.android" target="_blank" rel="noopener noreferrer nofollow" className="link link-focus">
                            <img className="h-custom" src="/play-store.svg" alt="Videogum on Play Store"/>
                        </a>
                    </div>
                </div>
            </section>

            <section id="community-links">
                <div className="flex flex-col items-center justify-center py-8">
                    <div className="flex gap-2">
                        <a href="https://discord.gg/kQ5NjJ8dBC" target="_blank" rel="noopener noreferrer nofollow" >
                            <h2 className="text-2xl font-bold underline cursor-pointer link link-focus">Join Our Discord</h2>
                        </a>                        
                    </div>
                    <div className="w-[80vw] sm:max-w-[256px] p-4">
                        <img src="VG-Discord.png" alt="Videogum on Discord" width={"100%"} className="rounded-md"/>
                    </div>
                </div>
            </section>
        </Page>
    )
}