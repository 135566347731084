import React, { FC, useEffect } from "react"
import { Page } from "../page"
import { VGUser } from "../../data/schema"
import { getUser, updateUser } from "../../lib/vg-client"
import { Button } from "../../components/ui/button"
import { Label } from "../../components/ui/label"
import { Input } from "../../components/ui/input"
import { Card, CardContent, CardFooter } from "../../components/ui/card"
import { Avatar, AvatarFallback, AvatarImage } from "../../components/ui/avatar"
import { useSessionContext } from "supertokens-auth-react/recipe/session"
import { useToast } from "../../components/ui/use-toast"

export interface ProfilePageProps {}

export const ProfilePage: FC<ProfilePageProps> = ()=>{

    const [user, setUser] = React.useState<VGUser | undefined>(undefined);

    const [userForm, setUserForm] = React.useState<Partial<VGUser>>({
        username: "",
        email: "",
        first_name: "",
        last_name: "",
        avatar: ""
    });

    const { userId }:any = useSessionContext();
    const {toast} = useToast()
    const [loading, setLoading] = React.useState(true);

    const fetchUser = React.useCallback(async ()=>{
        try {
            const resp = await getUser(userId);
            if(resp.data){
                setUser(resp.data);
                setUserForm({
                    username: resp.data.username,
                    first_name: resp.data.first_name,
                    last_name: resp.data.last_name,
                    email: resp.data.email,
                    avatar: resp.data.avatar
                })
            }
        } catch (error) {
            console.error("Error fetching user", error)
            toast({
                title: "Error fetching user",
                description: `err: ${error}`,
            })
        } finally{
            setLoading(false);
        }
    }, [userId, toast]);

    const handleInputChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>)=>{
        setUserForm(f=>({...f, [e.target.id]: e.target.value }));
    },[]);

    const submitUpdateUser = React.useCallback(async ()=>{
        if (!userForm) return;

        try {
            const resp = await updateUser(userId, userForm);
            if(resp.data){
                setUser(resp.data);
                toast({
                    title: "Successfully Updated User",
                    description: `naisu!`,
                })
            }
        } catch (error) {
            console.error("Error updating user", error)
            toast({
                title: "Error updating user",
                description: `err: ${error}`,
            })
        }
    },[userForm, userId, toast]);

    useEffect(()=>{
        if(loading){
            fetchUser();
        }
    },[loading, fetchUser])

    return (
        <Page>
            <h1>Profile</h1>
            <section id='user-info'>
                <div className="flex w-full items-center justify-center">
                    <Card>
                        <CardContent>
                            <div className="grid gap-4 py-4">
                                <div className="items-center gap-4">
                                    
                                    <Avatar>
                                        <AvatarImage src={userForm.avatar} ></AvatarImage>
                                        <AvatarFallback>{userForm.username && userForm.username[0]}</AvatarFallback>
                                    </Avatar>
                                    <div className="grid grid-cols-4 items-center gap-4">
                                        <Label htmlFor="avatar" className="text-right">
                                        Avatar
                                        </Label>
                                        <Input
                                            id="avatar"
                                            name="avatar"
                                            defaultValue={userForm?.avatar}
                                            onChange={handleInputChange}
                                            className="col-span-3"
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-4 items-center gap-4">
                                    <Label htmlFor="email" className="text-right">
                                    Email
                                    </Label>
                                    <Input
                                        id="email"
                                        name="email"
                                        defaultValue={userForm?.email}
                                        onChange={handleInputChange}
                                        className="col-span-3"
                                    />
                                </div>
                                <div className="grid grid-cols-4 items-center gap-4">
                                    <Label htmlFor="first_name" className="text-right">
                                    First Name
                                    </Label>
                                    <Input
                                        id="first_name"
                                        name="first_name"
                                        defaultValue={userForm?.first_name}
                                        onChange={handleInputChange}
                                        className="col-span-3"
                                    />
                                </div>
                                <div className="grid grid-cols-4 items-center gap-4">
                                    <Label htmlFor="last_name" className="text-right">
                                    Last Name
                                    </Label>
                                    <Input
                                        id="last_name"
                                        name="last_name"
                                        defaultValue={userForm?.last_name}
                                        onChange={handleInputChange}
                                        className="col-span-3"
                                    />
                                </div>
                                <div className="grid grid-cols-4 items-center gap-4">
                                    <Label htmlFor="username" className="text-right">
                                    Username
                                    </Label>
                                    <Input
                                        id="username"
                                        name="username"
                                        defaultValue={userForm?.username}
                                        onChange={handleInputChange}
                                        className="col-span-3"
                                        prefix="@"
                                    />
                                </div>
                                {/* <div className="grid grid-cols-4 items-center gap-4">
                                    <Label htmlFor="isPublic" className="text-right">
                                    Make Public
                                    </Label>
                                    <Checkbox
                                        id='isPublic'
                                        name='isPublic'
                                        checked={assetDetails?.isPublic}
                                        onCheckedChange={handleCheckChange}
                                    />
                                </div> */}
                            </div>
                        </CardContent>
                        <CardFooter >
                            <Button type="submit" disabled={false} onClick={submitUpdateUser}>Update</Button>
                        </CardFooter>
                    </Card>
                </div>
                
            </section>
        </Page>
    )
}

export default ProfilePage