import React from 'react';

import { Copy } from "lucide-react"
import { toast } from "sonner"

export interface LilCopyProps {
    value: string | any;
}

export const LilCopy: React.FC<LilCopyProps> = ({value}) => {
    return <Copy className="cursor-pointer" onClick={() => {
        navigator.clipboard.writeText(value);
        toast("Item has been copied to clipboard", {
          description: value,
          action: {
            label: "OK",
            onClick: () => console.log("OK"),
          },
        });
      }}
      size={16}
    />
}