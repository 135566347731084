
import React, { FC } from 'react';
import { Page } from '../page';
import { DataTable } from '../../components/data-table';
import { assetColumns } from '../../components/columns';
// import { tasks } from '../../data/seed';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
//   DialogTrigger,
} from "../../components/ui/dialog"
import { Input } from "../../components/ui/input"
import { Button } from "../../components/ui/button"
import { Label } from "../../components/ui/label"
import { Checkbox } from "../../components/ui/checkbox"

import {cn} from '../../lib/utils';
import { uploadFile } from '../../lib/vg-client';

// import Uppy from '@uppy/core';
//import Dashboard from '@uppy/dashboard';
//import DragDrop from '@uppy/drag-drop';
// import Tus from '@uppy/tus';
// import '@uppy/core/dist/style.min.css';
// import '@uppy/dashboard/dist/style.min.css';
// import {Dashboard,DragDrop} from '@uppy/react';
// import XHR from '@uppy/xhr-upload';

import {useDropzone} from 'react-dropzone' //Accept

import { AssetMetadata, VGAsset, VGResponse } from '@/data/schema';
import { DataTableRowActions } from '../../components/data-table-row-actions';
import { CellContext, Row } from '@tanstack/react-table';
import { CheckedState } from '@radix-ui/react-checkbox';
import { useToast } from '../../components/ui/use-toast';
import { Loading } from '../../components/loading';


type AssetDetails = {
    is_public?: boolean;
    metadata?: AssetMetadata;
    file?: File;
}

const defaultAssetDetails: AssetDetails = {
    
    is_public: false,
    metadata: {
        name: "",
        description: "",
        is_gen_ai: false,
        prompt: "",
        tags: [],
        genres: [],
        category: "",
        preview_url: undefined
    },
    file: undefined
}

interface UploadDropzoneProps {
    onUploadComplete?: (asset: VGAsset)=>void;
}

const UploadDropzone: FC<UploadDropzoneProps> = ({
    onUploadComplete
}) => {

    const { toast } = useToast();

    const [isUploading, setUploading] = React.useState<boolean>(false);
    const [showFormDialog, setShowFormDialog] = React.useState<boolean>(false);
    const [assetDetails, setAssetDetails] = React.useState<AssetDetails>(defaultAssetDetails);

    const progressRef = React.useRef<HTMLProgressElement>(null);

    const onDrop = React.useCallback((acceptedFiles: Array<File>) => {
        // Do something with the files
        console.log("MUH FILES", acceptedFiles)
        setAssetDetails({
            is_public: false,
            metadata: {
                is_gen_ai: false,
                prompt: "",
                name: acceptedFiles[0].name,
                description: "this is a description",
            },
            file: acceptedFiles[0]
        })
        setShowFormDialog(true);

        // if(acceptedFiles[0].type.includes("image")){
            
        // }
        // handleFiles(acceptedFiles)
    }, [])

    const handleUpload = React.useCallback(async () => {
        console.log("handleUpload")
        console.log("asset Details: ", assetDetails)
        if(assetDetails){
            if(progressRef.current){
                progressRef.current.value = 0;
            }
            setUploading(true);
            // Create a FormData object and append the selected file
            const formData = new FormData();
            formData.append('is_public', assetDetails.is_public ? "true" : "false");
            formData.append('metadata', JSON.stringify(assetDetails.metadata || {}));
            //formData.append('type', files[0].type);
            if(assetDetails.file){
                formData.append('file', assetDetails.file);
            }
            console.log("formData: ", formData)
            try {
                const res = await uploadFile(
                    formData,
                    (prog: number)=>{
                        if(progressRef.current){
                            progressRef.current.value = prog;
                        }
                    }
                )
                console.log(res)
                if(res.data && onUploadComplete){
                    onUploadComplete(res.data);
                }
                setShowFormDialog(false);
                
                // Optionally, perform any additional actions after successful upload
                
                // Clear the input field for future uploads
                //fileInput.current && fileInput.current?.value = '';
            
            } catch (error) {
                console.error(error);
                toast({
                    title: `Upload failed...🥺`,
                    description: `An error occurred while uploading the file.`,
                });
                
            } finally {
                setUploading(false);
                setAssetDetails(defaultAssetDetails);
                
                
            }
        }
    },[assetDetails, onUploadComplete, toast, progressRef]);

    const handleSend = React.useCallback(async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        console.log("send it")
        await handleUpload();
    },[handleUpload])

   
    const handleInputChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>)=>{
        setAssetDetails(d=>({...d, [e.target.name]: e.target.value}))
    },[]);

    const handleMetaInputChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>)=>{
        setAssetDetails(d=>({...d, metadata: { ...d.metadata, [e.target.name]: e.target.value} }))
    },[]);

    const handleCheckChange = React.useCallback((checked: CheckedState)=>{
        setAssetDetails(d => ({...d, is_public: !!checked}))
    },[]);
    
    //acceptedFiles, fileRejections
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop: onDrop,
        maxFiles: 1, //10
        maxSize: 500000000, // 1000000000,
        accept: {
            'image/jpeg': [],
            'image/png': [],
            // 'image/gif': [],
            // 'image/svg+xml': [],
            // 'image/webp': [],
            // 'image/bmp': [],
            // 'image/tiff': [],
            'video/mp4': [],
            'video/quicktime': [],
            'video/avi': [],
            'video/mpeg': [],
            'video/webm': [],
            'video/ogg': [],
            // 'video/x-m4v': [],
            // 'video/x-ms-wmv': [],
            // 'video/x-flv': [],
            'audio/mpeg': [],
            'audio/ogg': [],
            'audio/wav': [],
            // 'audio/webm': [],
            'audio/aac': [],
            'audio/flac': [],
            'audio/mp4': [],
            'audio/opus': [],
            'audio/x-wav': [],
            // 'audio/x-aiff': []
        }
    })

    // const acceptedFileItems = acceptedFiles.map(file => (
    //     <li key={file.name}>
    //       {file.name} - {file.size} bytes
    //     </li>
    //   ));
    
    //   const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    //     <li key={file.name}>
    //       {file.name} - {file.size} bytes
    //       <ul>
    //         {errors.map(e => (
    //           <li key={e.code}>{e.message}</li>
    //         ))}
    //       </ul>
    //     </li>
    //   ));

    const prog = React.useMemo(()=><div className="flex flex-col justify-center items-center gap-2 py-4">
        <Loading />
        <h2>Uploading</h2>
        <progress ref={progressRef} className="w-full h-5 [&::-webkit-progress-bar]:rounded-md [&::-webkit-progress-value]:rounded-md [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-orange-400 [&::-moz-progress-bar]:bg-orange-400 [&::-webkit-progress-value]:transition-all [&::-webkit-progress-value]:duration-500"></progress>
    </div>
    , [progressRef]);

    return <div className={'p-4'}>
        <div {...getRootProps()} className={cn("w-1/2 mx-auto bg-background p-6 rounded-md text-center border-dashed border-orange-400 border-2")}>
            <div className="p-4 min-h-[25vh] flex justify-center items-center">
                {isUploading ? prog : <>
                    <input {...getInputProps()} />
                    {
                    isDragActive ?
                        <p>Drop the files here ...</p> :
                        <div>
                            <p className="text-lg">Drag 'n' drop some files here, or click to select files</p>
                            <p className='text-sm'>File types includes: .mp3, .mp4, .wav, .jpg, .png, etc.</p>
                        </div>
                    }
                    </>
                }
            </div>
        </div>
        {/* <aside>
            <h4>Accepted files</h4>
            <ul>{acceptedFileItems}</ul>
            <h4>Rejected files</h4>
            <ul>{fileRejectionItems}</ul>
        </aside> */}
        <Dialog open={showFormDialog} onOpenChange={(open)=>{ setShowFormDialog(false); }}>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Upload Asset</DialogTitle>
                    <DialogDescription>Give your asset a name.</DialogDescription>
                </DialogHeader>
                    <div className="grid gap-4 py-4">
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="name" className="text-right">
                            Name
                            </Label>
                            <Input
                                id="name"
                                name="name"
                                defaultValue={assetDetails?.metadata?.name}
                                onChange={handleMetaInputChange}
                                className="col-span-3"
                            />
                        </div>
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="description" className="text-right">
                            Description
                            </Label>
                            <Input
                                id="description"
                                name="description"
                                defaultValue={assetDetails?.metadata?.description}
                                onChange={handleMetaInputChange}
                                className="col-span-3"
                            />
                        </div>
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="isPublic" className="text-right">
                            Make Public
                            </Label>
                            <Checkbox
                                id='is_public'
                                name='is_public'
                                checked={assetDetails?.is_public}
                                onCheckedChange={handleCheckChange}
                            />
                        </div>
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="preview_url" className="text-right">
                            Preview URL
                            </Label>
                            <Input
                                id="preview_url"
                                name="preview_url"
                                defaultValue={assetDetails?.metadata?.preview_url}
                                onChange={handleMetaInputChange}
                                className="col-span-3"
                            />
                        </div>
                    </div>
                <DialogFooter>
                    <Button type="submit" disabled={isUploading} onClick={handleSend}>Upload</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
      </div>
    
}

// action="http://localhost:9200/api/v1/upload" method="post"
const env = window.location.origin.includes("dev") ? "dev": window.location.origin.includes("stag") ? "stag" : "";
const api_root = window.location.origin.includes("localhost") ? window.location.origin.replace(":3000", ":9200") : `https://${env}-api.videogum.app`;


export interface UploadPageProps {
    children?: React.ReactNode;
}

export const UploadPage: FC<UploadPageProps> = ({
    children
})=>{

    const { toast } = useToast();

    const [assetsLoaded, setAssetsLoaded] = React.useState<boolean>(false);
    const [assets, setAssets] = React.useState<Array<VGAsset>>([
        {
            id: "54da0613-e9b1-4ce6-9884-07982eded2c4",
            created_at: new Date().toUTCString(),
            updated_at: new Date().toUTCString(),
            host: "https://videogum.sfo3.cdn.digitaloceanspaces.com",
            content_type: "video/mp4",
            file_size: 15000,
            file_name: "54da0613-e9b1-4ce6-9884-07982eded2c4",
            file_path: "d/5534ffdc-2916-4ed2-9119-8b25ce1ae731/v",
            file_ext: "mp4",
            owner_id: "5534ffdc-2916-4ed2-9119-8b25ce1ae731",
            content_url: "https://videogum.sfo3.cdn.digitaloceanspaces.com/d/5534ffdc-2916-4ed2-9119-8b25ce1ae731/v/54da0613-e9b1-4ce6-9884-07982eded2c4.mp4",
            creator: {username: "videogum", "user_id": "5534ffdc-2916-4ed2-9119-8b25ce1ae731"},
            is_public: true,
            metadata: {
                name: "sample video",
                description: "this is a sample video",
                preview_url: "https://videogum.sfo3.cdn.digitaloceanspaces.com/d/5534ffdc-2916-4ed2-9119-8b25ce1ae731/v/p/54da0613-e9b1-4ce6-9884-07982eded2c4.jpg",
                is_gen_ai:  false,
                prompt: "",
                tags: ["sample", "video"],
                genres: ["action", "comedy"],
                category: "movie",
            }
        }
    ]);

    const fetchAssets = React.useCallback(async ()=>{
        console.log("fetching assets")
        setAssetsLoaded(false);
        try {
            const resp = await fetch(`${api_root}/api/v1/assets`)
            console.log("resp ", resp)
            if(resp.ok && resp.status >= 200 && resp.status < 300){
                const {success, message, data } = await resp.json() as VGResponse;
                console.log("data is: ", success, message, data)
                setAssets(data);
            } else {
               throw new Error('failed to fetch assets')
            }
        } catch(err){
            console.error(err)
            toast({
                title: "Failed to get Assets",
                description: `error: ${err}`,
            });
        } finally {
            setAssetsLoaded(true)
        }
    },[toast])

    React.useEffect(()=>{
        if(!assetsLoaded){
            fetchAssets()
        }
    },[fetchAssets, assetsLoaded])

    // const dropZone = React.useRef<HTMLDivElement>(null);
    // const fileInput = React.useRef<HTMLInputElement>(null);
    // const dropZone = document.getElementById('drop-zone');
    // const fileInput = document.getElementById('file-input');

    // React.useEffect(()=>{
    //     if(dropZone.current && fileInput.current){
    //         dropZone.current.addEventListener('click', () => {
    //             fileInput.current?.click();
    //         });
        
    //         fileInput.current.addEventListener('change', () => {
    //             if(fileInput.current?.files)
    //                 handleFiles(fileInput.current?.files);
    //         });
        
    //         dropZone.current.addEventListener('dragover', (e) => {
    //             e.preventDefault();
    //             dropZone.current?.classList.add('border-blue-500');
    //         });
        
    //         dropZone.current.addEventListener('dragleave', () => {
    //             dropZone.current?.classList.remove('border-blue-500');
    //         });
        
    //         dropZone.current.addEventListener('drop', (e: DragEvent) => {
    //             e.preventDefault();
    //             dropZone.current?.classList.remove('border-blue-500');
    //             //handleFiles(e.dataTransfer.files);
    //         });
        
    //     }
    // },[dropZone])

    // const handleFormSubmit = React.useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    //     console.log('submit')
    //     e.preventDefault()
    // },[])

    // async function handleFiles(files: FileList | null) {
    //     console.log("got it", files)
    //     if(files){
    //         // Create a FormData object and append the selected file
    //         const formData = new FormData();
    //         formData.append('file', files[0]);
            
    //         try {
    //             const res = await uploadFile(formData)
    //             console.log(res)
                
                
    //             // Optionally, perform any additional actions after successful upload
                
    //             // Clear the input field for future uploads
    //             //fileInput.current && fileInput.current?.value = '';
            
    //         } catch (error) {
    //             console.error(error);
            
    //             alert('An error occurred while uploading the file.');
    //         }
    //     }
    // }

    // const u = React.useMemo(()=>{
    //     return new Uppy()
    //     .use(Tus, {endpoint: "http://localhost:9200/api/v1/upload/tus"})
    //     .use(DragDrop, { id: '#drag-drop' });
    // },[])
    
    // const [uppy] = React.useState(() => {
    //     return new Uppy()
    //     .use(Tus, {
    //         endpoint: "http://localhost:9200/api/v1/tus",
    //         withCredentials:true,
    //         addRequestId:true,
    //         limit: 10,
    //         retryDelays: [0, 1000, 3000, 5000],
    //     })
    //     // .use(XHR, {
    //     //     endpoint: "http://localhost:9200/api/v1/assets/upload",
    //     // })
    //     //.use(DragDrop, { id: '#drag-drop' })
    // });

    // const fetchSession = React.useCallback(async ()=>{
    //     try {
    //         const resp = await fetch(`${api_root}/api/test/sessionInfo`)
    //         console.log("resp ", resp)
    //         if(resp.ok && resp.status === 200){
    //             const {success, message, data } = await resp.json() as VGResponse;
    //             console.log("data is: ", success, message, data)
    //         } else {
    //            throw new Error('failed to fetch session')
    //         }
    //     } catch(err){
    //         console.error(err)
    //     }
    // },[]);

    const handleDelete = React.useCallback(async (row: VGAsset) => {
        console.log("delete asset: ", row.id)
        try {
          const resp = await fetch(`${api_root}/api/v1/assets/${row.id}`, {
            method: 'DELETE'
          });
          if (resp.ok) {
            console.log("deleted asset: ", row.id)
            setAssets(assets.filter((asset)=>asset.id !== row.id))
            toast({
                title: `Asset deleted`,
                description: `Asset: ${row.id} deleted successfully.`,
            });
          }
    
        }catch(err) {
          console.error(err)
          toast({
            title: `Failed to delete Asset: ${row.id}`,
            description: `error: ${err}`,
        });
        }
      },[assets, toast])

    const handleEdit = React.useCallback((row: VGAsset) => {
        console.log("edit asset: ", row)
        //show the dialog to edit the asset
        
    },[]);

    const assetDataColumns = React.useMemo(()=>{
        return [...assetColumns, 
            {
                id: "actions",
                cell: ({ row }: CellContext<VGAsset, unknown>) => <DataTableRowActions row={row} onSelect={(row: Row<VGAsset>, selection: string)=>{
                    switch(selection){
                        case "delete":
                        handleDelete(row.original as VGAsset);
                        break;
                        case "edit":
                        handleEdit(row.original as VGAsset);
                        break;
                    }
                }} />,
            }
        ]
    },[handleDelete, handleEdit])

    return (
        <Page>
            {/* <section id="session">
                <button onClick={fetchSession}>check session</button>
            </section> */}
            <section id="upload" className={"min-h-[25vh]"}>
                <UploadDropzone onUploadComplete={(asset: VGAsset)=>{
                    setAssets(a=>[...a, asset]);
                    toast({
                        title: `Upload success`,
                        description: `Asset: ${asset.metadata?.name} uploaded successfully.`,
                    });
                }}/>
                {/* <div className={cn("max-w-md mx-auto bg-white p-6 rounded-md shadow-md")}>
                    <h2 className={cn("text-xl font-semibold mb-4")}>Drag and Drop Image or Video</h2>
                    <div className={cn("drop-zone")} id="drop-zone" ref={dropZone}>
                        <p className={cn("text-gray-500")}>Drag &amp; Drop files here</p>
                    </div>
                   
                    <form id="uploadForm" onSubmit={handleFormSubmit} encType="multipart/form-data" className={cn("mt-4")}>
                        <input ref={fileInput} type="file" id="file-input" name="file" className={cn("hidden")} accept="image/*,video/*" />
                        <button type="submit" className={cn("bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md")}>Upload</button>
                    </form>
                </div> */}
            </section>
            <section id="drag-drop">
                {/* <Dashboard
                    uppy={uppy}
                    // plugins={[]}
                    proudlyDisplayPoweredByUppy={false}
                    showProgressDetails={true}
                    hideUploadButton={false}
                /> */}
                {/* <DragDrop id="drag-drop" uppy={uppy} /> */}
            </section>
            <section id="data" className='p-10'>
                {assetsLoaded ? <DataTable columns={assetDataColumns} data={assets} /> : <div className="flex justify-center content-center w-full min-h-[200px]" ><Loading /> </div> }
                {children}
            </section>
        </Page>
    )
}

export default UploadPage;