
import { ColumnDef } from "@tanstack/react-table"

import { Badge } from "../components/ui/badge"
import { Checkbox } from "../components/ui/checkbox"
import { LilCopy } from "../components/lil-copy"

import { labels, priorities, statuses } from "../data/data"
import { VG_ENV } from "../data/constants"
import { Task, VGAsset } from "../data/schema"
import { DataTableColumnHeader } from "./data-table-column-header"
import { DataTableRowActions } from "./data-table-row-actions"

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../components/ui/dialog"
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar"
import { Card, CardContent } from "./ui/card"

//import { Button } from "./ui/button"

export const columns: ColumnDef<Task>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Task" />
    ),
    cell: ({ row }) => <div className="w-[80px]">{row.getValue("id")}</div>,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "title",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Title" />
    ),
    cell: ({ row }) => {
      const label = labels.find((label) => label.value === row.original.label)

      return (
        <div className="flex space-x-2">
          {label && <Badge variant="outline">{label.label}</Badge>}
          <span className="max-w-[500px] truncate font-medium">
            {row.getValue("title")}
          </span>
        </div>
      )
    },
  },
  {
    accessorKey: "status",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    cell: ({ row }) => {
      const status = statuses.find(
        (status) => status.value === row.getValue("status")
      )

      if (!status) {
        return null
      }

      return (
        <div className="flex w-[100px] items-center">
          {status.icon && (
            <status.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span>{status.label}</span>
        </div>
      )
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
  {
    accessorKey: "priority",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Priority" />
    ),
    cell: ({ row }) => {
      const priority = priorities.find(
        (priority) => priority.value === row.getValue("priority")
      )

      if (!priority) {
        return null
      }

      return (
        <div className="flex items-center">
          {priority.icon && (
            <priority.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span>{priority.label}</span>
        </div>
      )
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
  {
    id: "actions",
    cell: ({ row }) => <DataTableRowActions row={row} />,
  },
]


export const assetColumns: ColumnDef<VGAsset>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ID" />
    ),
    cell: ({ row }) => <div className="w-[64px]">
      <LilCopy value={row.original.id} />
    </div>,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "creator",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Creator" />
    ),
    cell: ({ row }) => <Card className="p-4">
        <div className="flex items-center space-x-4">
            <Avatar className="h-10 w-10">
              <AvatarImage sizes="sm" src={`${row.original.host}/${VG_ENV[0]}/${row.original.creator.user_id}/avatar.jpg`} alt={row.original.creator.username} />
              <AvatarFallback>{row.original.creator.username[0]}</AvatarFallback>
            </Avatar>
            <div>@{row.original.creator.username}</div>
        </div>
    </Card>,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "file_path",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Preview" />
    ),
    cell: ({ row }) => {
      // const label = labels.find((label) => label.value === row.original.name)
      //const previewPath = `${row.original.host}/${row.original.file_path}/p/${row.original.file_name}.jpg`;
      //const assetPath = `${row.original.host}/${row.original.file_path}/${row.original.file_name}.${row.original.file_ext}`;
      const previewPath = `${row.original.metadata?.preview_url}`;
      const assetPath = `${row.original.content_url}`;
      return (
        <div className="flex space-x-2">
          {/* {label && <Badge variant="outline">{label.label}</Badge>} */}
          <span className="max-w-[150px] truncate font-medium">
            
            <Dialog>
              <DialogTrigger asChild>
                <img
                  width={150}
                  height={150}
                  src={previewPath}
                  alt="preview of the asset"
                  className="cursor-pointer rounded-md hover:shadow-md"
                />
              </DialogTrigger>
              <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                  <DialogTitle>{row.original.metadata?.name}</DialogTitle>
                  <DialogDescription>
                    {row.original.metadata?.description}
                  </DialogDescription>
                </DialogHeader>
                {row.original.content_type.includes('video') ?
                  <video controls autoPlay={false}>
                    <source src={assetPath} type={row.original.content_type} />
                    Your browser does not support the video tag.
                  </video>
                  :
                  row.original.content_type.includes('audio') ?
                  <audio controls autoPlay={false}>
                    <source src={assetPath} type={row.original.content_type} />
                    Your browser does not support the audio element.
                  </audio>
                  : null
                }
              </DialogContent>
            </Dialog>
            
          </span>
        </div>
      )
    },
  },
  {
    accessorKey: "name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
    cell: ({ row }) => {
      // const label = labels.find((label) => label.value === row.original.name)

      return (
        <div className="flex space-x-2">
          {/* {label && <Badge variant="outline">{label.label}</Badge>} */}
          <span className="max-w-[500px] truncate font-medium">
            {row.original.metadata?.name}
          </span>
        </div>
      )
    },
  },
  {
    accessorKey: "file_name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="File Name" />
    ),
    cell: ({ row }) => {
      // const label = labels.find((label) => label.value === row.original.file_name)

      return (
        <div className="flex space-x-2">
          {/* {label && <Badge variant="outline">{label.label}</Badge>} */}
          <span className="max-w-[500px] truncate font-medium">
            {row.getValue("file_name")}
          </span>
        </div>
      )
    },
  },
  {
    accessorKey: "file_size",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Size" />
    ),
    cell: ({ row }) => {
    
      return (
        <div className="flex w-[100px] items-center">
          {b2mb(row.getValue("file_size") as number)} MB
        </div>
      )
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
  
]

function b2mb(b: number) {
  return (b / 1024 / 1024).toFixed(2)
}