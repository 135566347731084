import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function includesJSON(value: string | undefined): string | undefined {
  if (!value) { return value }
  const startIndex = value.indexOf("{")
  const endIndex = value.lastIndexOf("}")
  
  if(startIndex !== -1 && endIndex !== -1 && startIndex < endIndex){
    return value.slice(startIndex, endIndex+1)
  }
  return undefined
}