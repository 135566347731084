import React, { useEffect } from 'react'
//import Image from "next/image"

import { Page } from "../page"

import { Button } from "../../components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card"
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
//   DialogTrigger,
} from "../../components/ui/dialog"
import { Input } from "../../components/ui/input"
import { Label } from "../../components/ui/label"
// import { Checkbox } from "../../components/ui/checkbox"
// import { CalendarDateRangePicker } from "@/app/examples/dashboard/components/date-range-picker"
import { Overview } from "../../components/overview"
import { VGEndpoints, createProject, createSequence, createStudio, deleteProject, deleteStudio, getCollection, getStudioProjectSequences, getStudioProjects, getStudios, updateProject } from '../../lib/vg-client'
import { VGProject, VGSequence, VGStudio } from '../../data/schema'

import {Book, Edit, PlusSquare, Trash} from 'lucide-react';
// import { CheckedState } from '@radix-ui/react-checkbox'
// import { Select, SelectItem, SelectLabel, SelectTrigger, SelectValue, SelectContent, SelectGroup } from '../../components/ui/select'
import { Chatterface } from '../../components/chatterface'
import { Loading } from '../../components/loading';
import { Checkbox } from '../../components/ui/checkbox'
import { WebsocketProvider, useWs } from '../../components/socket'
import { SessionContextType, useSessionContext } from 'supertokens-auth-react/recipe/session'
import { useToast } from '../../components/ui/use-toast'




// export const metadata: Metadata = {
//   title: "Dashboard",
//   description: "Example dashboard app built using the components.",
// }



type StudioForm = Partial<VGStudio>
const defaultStudio: StudioForm = {
  name: 'My new Studio',
  // description: 'This studio is for...',
  preview: 'https://videogum.sfo3.cdn.digitaloceanspaces.com/public/vgStudio01.jpg',
  projects: [],
  members: []
}

type ProjectForm = Partial<VGProject>
const defaultProject: ProjectForm = {
  name: 'My new Project',
  // description: 'This project is for...',
  preview: 'https://videogum.sfo3.cdn.digitaloceanspaces.com/public/vgStudio02.jpg',
  sequences: [],
  members: [],
  studio_id: '',
}

type SequenceForm = Partial<VGSequence>
const defaultSequence: SequenceForm = {
  title: 'My new Sequence',
  project_id: '',
}

/* eslint-disable-next-line */
interface VideoGumDataProps {}


const VideoGumData: React.FC<VideoGumDataProps> = () => {

  const [dataFetched, setDataFetched] = React.useState(false)
  const [loadingStudios, setLoadingStudios] = React.useState(false)
  const [loadingProjects, setLoadingProjects] = React.useState(false)
  const [loadingSequences, setLoadingSequences] = React.useState(false)

  const [studios, setStudios] = React.useState<Array<VGStudio>>([])
  const [selectedStudio, setSelectedStudio] = React.useState<string | undefined>(undefined)
  const [projects, setProjects] = React.useState<Array<VGProject>>([])
  const [selectedProject, setSelectedProject] = React.useState<string | undefined>(undefined)
  const [sequences, setSequences] = React.useState<Array<VGSequence>>([])
  const [selectedSequence, setSelectedSequence] = React.useState<VGSequence | undefined>(undefined)

  const { userId }:any = useSessionContext();
  const {ready, value, send} = useWs();


  const { toast } = useToast();

  useEffect(()=>{
    if(ready && userId){
      console.log("ready")
      send(JSON.stringify({id: userId, type: "register"}));
    }
  },[ready, userId, send])

  useEffect(()=>{
    if(value){
      console.log("got message", value)
    }
  },[value])

  const fetchStudios = React.useCallback(async () => {
    try {
      setLoadingStudios(true)
      const response = await getCollection<VGStudio>(VGEndpoints.STUDIOS);
      if(response.data){
        setStudios(response.data);
      }
    } catch (error) {
      console.log(error)
      toast({
        title: `Failed to get Studios`,
        description: `err: ${error}`,
      });
    }
    finally {
      setLoadingStudios(false)
    }
    
  },[toast])

  const fetchProjects = React.useCallback(async (studio_id?:string) => {
    if (!studio_id) { console.warn("missing studio_id"); return; }
    try {
      setLoadingProjects(true)
      setProjects([])
      const response = await getStudioProjects(studio_id);
      // const response = await getCollection<VGProject>(VGEndpoints.PROJECTS, { studio_id: studio_id });
      if(response.data){
        setProjects(response.data);
        setSelectedProject(undefined);
      }
    } catch (error) {
      console.log(error)
      toast({
        title: `Failed to get Projects`,
        description: `err: ${error}`,
      });
    }
    finally {
      setLoadingProjects(false)
    }
    
  },[toast])

  const fetchSequences = React.useCallback(async (studio_id?:string, project_id?:string) => {
    if (!project_id || !studio_id) { console.warn("missing project_id"); return; }
    try {
      setLoadingSequences(true)
      setSequences([])
      const response = await getStudioProjectSequences(studio_id, project_id);
      //const response = await getCollection<VGSequence>(VGEndpoints.SEQUENCES, { project_id: project_id});
      if(response.data){
        setSequences(response.data);
      }
    } catch (error) {
      console.log(error)
      toast({
        title: `Failed to get Sequences`,
        description: `err: ${error}`,
      });
    }
    finally {
      setLoadingSequences(false)
    }
    
  },[toast])

  useEffect(() => {
    if (!dataFetched) {
      fetchStudios()
      setDataFetched(true)
    }
  }, [dataFetched,fetchStudios])


  // const handleSelectChange = React.useCallback((value:string)=>{
  //   setSelectedStudio(value);
  // },[])

  useEffect(()=>{
    if(selectedStudio){
      fetchProjects(selectedStudio);
    }
  },[selectedStudio, fetchProjects])

  useEffect(()=>{
    if(selectedProject && selectedStudio){
      fetchSequences(selectedStudio, selectedProject);
    }
  },[selectedStudio, selectedProject, fetchSequences])

  const [showStudioForm, setShowStudioForm] = React.useState(false)
  const [showProjectForm, setShowProjectForm] = React.useState(false)
  const [showUpdateProjectForm, setShowUpdateProjectForm] = React.useState(false)
  const [showSequenceForm, setShowSequenceForm] = React.useState(false)
  const [studioForm, setStudioForm] = React.useState<StudioForm>(defaultStudio);
  const [projectForm, setProjectForm] = React.useState<ProjectForm>(defaultProject);
  const [sequenceForm, setSequenceForm] = React.useState<SequenceForm>(defaultSequence);
  
  const handleAddStudio = React.useCallback(() => {
    setShowStudioForm(true)
  },[]);

  const handleAddProject = React.useCallback(() => {
    setShowProjectForm(true)
  },[]);

  const handleUpdateProject = React.useCallback(() => {
    setShowUpdateProjectForm(true);
  },[]);

  const handleAddSequence = React.useCallback(() => {
    setShowSequenceForm(true)
  },[]);

  const handleInputChangeStudio = React.useCallback((e: React.ChangeEvent<HTMLInputElement>)=>{
    setStudioForm(d=>({...d, [e.target.name]: e.target.value}))
  },[]);

  const handleInputChangeProject = React.useCallback((e: React.ChangeEvent<HTMLInputElement>)=>{
    setProjectForm(d=>({...d, [e.target.name]: e.target.value}))
  },[]);

  const handleInputChangeSequence = React.useCallback((e: React.ChangeEvent<HTMLInputElement>)=>{
    setSequenceForm(d=>({...d, [e.target.name]: e.target.value}))
  },[]);

  // const handleCheckChange = React.useCallback((checked: CheckedState)=>{
  //   setStudioForm(d => ({...d, isPublic: !!checked}))
  // },[]);

  const submitAddStudio = React.useCallback(async () => {
    try {
      const resp = await createStudio(studioForm);
      if(resp.data){
        setStudios(d => [...d, resp.data as VGStudio]);
      }
    } catch (error) {
      console.log(error)
    } finally {
      setShowStudioForm(false)
      setStudioForm(defaultStudio)
    }
  },[studioForm]);

  const handleDeleteStudio = React.useCallback(async (id: string)=>{
    console.log("delete studio", id)
    try{
      const resp = await deleteStudio(id);
      console.log(resp.message);
      setStudios(d => d.filter(s => s.id !== id));
      toast({
        title: `${resp.message || 'Studio Deleted'}`,
        description: `${id}`,
      });
    } catch (error) {
      console.log(error)
      toast({
        title: `Failed to delete studio ${id}`,
        description: `err: ${error}`,
      });
    } finally {
      setSelectedStudio(undefined)
    }
    console.log("delete studio")
  },[toast]);

  const submitAddProject = React.useCallback(async () => {
    if(!selectedStudio){ console.warn("no selected studio"); return; }
    try {
      const resp = await createProject({...projectForm, studio_id: selectedStudio});
      if(resp.data){
        setProjects(d => [...d, resp.data as VGProject]);
      }
    } catch (error) {
      console.log(error)
      toast({
        title: `Failed to create project ${selectedStudio}`,
        description: `err: ${error}`,
      });
    } finally {
      setShowProjectForm(false)
      setProjectForm(defaultProject)
    }
  },[projectForm, selectedStudio, toast]);

  const submitUpdateProject = React.useCallback(async () => {
    if(!selectedStudio || !selectedProject){ console.warn("no selected studio or studio"); return; }
    try {
      const resp = await updateProject(selectedProject, projectForm);
      if(resp.data){
        setProjects(d => [...d, resp.data as VGProject]);
        toast({
          title: `Project Updated`,
          description: `project: ${selectedProject} updated`,
        });
      }
    } catch (error) {
      console.log(error)
      toast({
        title: `Failed to update project ${selectedProject}`,
        description: `err: ${error}`,
      });
    } finally {
      setShowProjectForm(false)
      setProjectForm(defaultProject)
    }
  },[projectForm, selectedProject, selectedStudio, toast]);

  const handleDeleteProject = React.useCallback(async (id: string)=>{
    console.log("delete project", id)
    try{
      const resp = await deleteProject(id);
      console.log(resp.message);
      setProjects(d => d.filter(s => s.id !== id));
      toast({
        title: `${resp.message || 'Project Deleted'}`,
        description: `${id}`,
      });
    } catch (error) {
      console.log(error)
      toast({
        title: `Failed to delete project ${id}`,
        description: `err: ${error}`,
      });
    } finally {
      setSelectedProject(undefined)
    }
  },[toast]);

  

  const submitAddSequence = React.useCallback(async () => {
    if(!selectedProject){ console.warn("no selected project"); return; }
    try {
      const resp = await createSequence({...sequenceForm, project_id: selectedProject });
      if(resp.data){
        setSequences(d => [...d, resp.data as VGSequence]);
      }
    } catch (error) {
      console.log(error)
      toast({
        title: `Failed to add sequence`,
        description: `err: ${error}`,
      });
    } finally {
      setShowSequenceForm(false)
      setSequenceForm(defaultSequence)
    }
  },[sequenceForm, selectedProject, toast]);

  // const studioSelect = React.useMemo(()=>{
  //   return <Select
  //     onValueChange={handleSelectChange}
  //   >
  //     <SelectTrigger >
  //       <SelectValue placeholder="Select a Studio" />
  //     </SelectTrigger>
  //     <SelectContent>
  //       <SelectGroup>
  //         <SelectLabel>Studios</SelectLabel>
  //         {
  //           studios.map(stu => <SelectItem key={stu.id} value={stu.id}>{stu.name}</SelectItem>)
  //         }
  //       </SelectGroup>
  //     </SelectContent>
  //     </Select>
  // },[studios, handleSelectChange])

  const userStudios = React.useMemo(()=>{
    
    return studios.map(stu => (
      <Card
        key={stu.id}
        onClick={()=>{ console.log("clicked: ", stu); setSelectedStudio(stu.id); }} className={['cursor-pointer'].join(" ")}
        style={{backgroundImage: `url(${stu.preview})`}}
        >
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">
            Studio: {stu.name}
          </CardTitle>
          <Book />
        </CardHeader>
        <CardContent>
          {/* <div className="text-2xl font-bold">{stu.description}</div> */}
          <p className="text-xs text-muted-foreground">
            {`created at: ${stu.created_at}`}
          </p>
          <p className="text-xs text-muted-foreground">
            {stu.id}
          </p>
          <Checkbox checked={stu.id === selectedStudio}/>
          <Button onClick={()=>{handleDeleteStudio(stu.id); }}><Trash/></Button>
        </CardContent>
      </Card>
    ))
  },[studios, selectedStudio, handleDeleteStudio]);

  const studioProjects = React.useMemo(()=>{
    return projects.map(proj => (
      <Card
        key={proj.id}
        onClick={()=>{ console.log("clicked: ", proj); setSelectedProject(proj.id); }}
        className={['cursor-pointer'].join(" ")}
        style={{backgroundImage: `url(${proj.preview})`}}
        >
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">
            Project: {proj.name}
          </CardTitle>
          <Book />
        </CardHeader>
        <CardContent>
          {/* <div className="text-2xl font-bold">{proj.description}</div> */}
          <p className="text-xs text-muted-foreground">
            {`created at: ${proj.created_at}`}
          </p>
          <p className="text-xs text-muted-foreground">
            {proj.id}
          </p>
          <Checkbox checked={proj.id === selectedProject}/>
          <Button onClick={handleUpdateProject} ><Edit/></Button>
          <Button onClick={()=>{handleDeleteProject(proj.id); }}><Trash/></Button>
        </CardContent>
      </Card>
    ))
  },[projects, selectedProject, handleUpdateProject, handleDeleteProject])

  const projectSequences = React.useMemo(()=>{
    return sequences.map(seq => (
      <Card
        key={seq.id}
        onClick={()=>{ console.log("clicked: ", seq); setSelectedSequence(seq); }}
        className='cursor-pointer'
        style={{backgroundImage: `url(${seq.preview_url})`}}
        >
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">
            Sequence: {seq.title}
          </CardTitle>
          <Book />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{seq.title}</div>
          <div className="text-l font-bold">{seq.sub_title}</div>
          <div className="text-m font-bold">duration: {seq.duration}</div>
          <div className="text-m font-bold">voice over:{seq.voice_over}</div>
          <div className="text-m font-bold">direction:{seq.direction}</div>
          {seq.suggestion_blocks && <div className="text-m font-bold">{seq.suggestion_blocks.length} blocks</div> }
          { seq.blocks && <div className="text-m font-bold">{seq.blocks.length} blocks</div> } 
          <p className="text-xs text-muted-foreground">
            {`created at: ${seq.created_at}`}
          </p>
          <p className="text-xs text-muted-foreground">
            {seq.id}
          </p>
          {selectedSequence && <Checkbox checked={seq.id === selectedSequence.id}/>}
        </CardContent>
      </Card>
    ))
  },[sequences, selectedSequence])

  return <div>
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
    
        <div className="">
          <div className="text-2xl font-bold text-center">STUDIOS</div>
          <div className="py-4 text-center">
            <Button onClick={handleAddStudio} ><PlusSquare /> </Button>
          </div>
          {loadingStudios ? <Loading /> : userStudios}
          <Dialog open={showStudioForm} onOpenChange={(open)=>{ setShowStudioForm(false); }}>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Create Studio</DialogTitle>
                    <DialogDescription>This is a studio.</DialogDescription>
                </DialogHeader>
                    <div className="grid gap-4 py-4">
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="name" className="text-right">
                            Name
                            </Label>
                            <Input
                                id="name"
                                name="name"
                                defaultValue={studioForm?.name}
                                onChange={handleInputChangeStudio}
                                className="col-span-3"
                            />
                        </div>
                        {/* <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="description" className="text-right">
                            Description
                            </Label>
                            <Input
                                id="description"
                                defaultValue={studioForm?.description}
                                onChange={handleInputChangeStudio}
                                className="col-span-3"
                            />
                        </div> */}
                        <div className="grid grid-cols-4 items-center gap-4">
                          {studioForm?.preview && <img src={studioForm?.preview} alt="preview" className="col-span-3 max-w-[200]" />}
                            <Label htmlFor="description" className="text-right">
                            Preview Image URL
                            </Label>
                            <Input
                                id="preview"
                                name="preview"
                                defaultValue={studioForm?.preview}
                                onChange={handleInputChangeStudio}
                                className="col-span-3"
                            />
                        </div>
                        {/* <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="isPublic" className="text-right">
                            Make Public
                            </Label>
                            <Checkbox
                                id='isPublic'
                                name='isPublic'
                                checked={assetDetails?.isPublic}
                                onCheckedChange={handleCheckChange}
                            />
                        </div> */}
                    </div>
                <DialogFooter>
                    <Button type="submit" disabled={false} onClick={submitAddStudio}>Submit</Button>
                </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
        <div className="">
          {/* {studioSelect} */}
          <div className="text-2xl font-bold text-center">PROJECTS</div>
          <div className="py-4 text-center">
            <Button onClick={handleAddProject} disabled={selectedStudio === undefined} ><PlusSquare /> </Button>
          </div>
          {loadingProjects ? <Loading /> : studioProjects}
          <Dialog open={showProjectForm} onOpenChange={(open)=>{ setShowProjectForm(false); }}>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Create Project</DialogTitle>
                    <DialogDescription>This is a project.</DialogDescription>
                </DialogHeader>
                    <div className="grid gap-4 py-4">
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="name" className="text-right">
                            Name
                            </Label>
                            <Input
                                id="name"
                                name='name'
                                defaultValue={projectForm?.name}
                                onChange={handleInputChangeProject}
                                className="col-span-3"
                            />
                        </div>
                        {/* <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="description" className="text-right">
                            Description
                            </Label>
                            <Input
                                id="description"
                                name='description'
                                defaultValue={projectForm?.description}
                                onChange={handleInputChangeProject}
                                className="col-span-3"
                            />
                        </div> */}
                        <div className="grid grid-cols-4 items-center gap-4">
                          {projectForm?.preview && <img src={projectForm?.preview} alt="preview" className="col-span-3 max-w-[200]" />}
                            <Label htmlFor="preview" className="text-right">
                            Preview Image URL
                            </Label>
                            <Input
                                id="preview"
                                name="preview"
                                defaultValue={projectForm?.preview}
                                onChange={handleInputChangeProject}
                                className="col-span-3"
                            />
                        </div>
                        {/* <div className="grid grid-cols-4 items-center gap-4">
                            {projectForm?.avatar && <img src={projectForm?.avatar} alt="avatar" className="col-span-3 max-w-[200]" />}
                            <Label htmlFor="description" className="text-right">
                            Studio Avatar Image URL
                            </Label>
                            <Input
                                id="avatar"
                                defaultValue={studioForm?.avatar}
                                onChange={handleInputChangeProject}
                                className="col-span-3"
                            />
                        </div> */}
                        {/* <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="isPublic" className="text-right">
                            Make Public
                            </Label>
                            <Checkbox
                                id='isPublic'
                                name='isPublic'
                                checked={assetDetails?.isPublic}
                                onCheckedChange={handleCheckChange}
                            />
                        </div> */}
                    </div>
                <DialogFooter>
                    <Button type="submit" disabled={false} onClick={submitAddProject}>Submit</Button>
                </DialogFooter>
            </DialogContent>
          </Dialog>
          <Dialog open={showUpdateProjectForm} onOpenChange={(open)=>{ setShowUpdateProjectForm(false); }}>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Update Project</DialogTitle>
                </DialogHeader>
                    <div className="grid gap-4 py-4">
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="name" className="text-right">
                            Name
                            </Label>
                            <Input
                                id="name"
                                name="name"
                                value={projectForm?.name}
                                onChange={handleInputChangeProject}
                                className="col-span-3"
                            />
                        </div>
                        {/* <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="description" className="text-right">
                            Description
                            </Label>
                            <Input
                                id="description"
                                value={projectForm?.description}
                                onChange={handleInputChangeProject}
                                className="col-span-3"
                            />
                        </div> */}
                        <div className="grid grid-cols-4 items-center gap-4">
                          {projectForm?.preview && <img src={projectForm?.preview} alt="preview" className="col-span-3 max-w-[200]" />}
                            <Label htmlFor="preview" className="text-right">
                            Preview Image URL
                            </Label>
                            <Input
                                id="preview"
                                name='preview'
                                value={projectForm?.preview}
                                onChange={handleInputChangeProject}
                                className="col-span-3"
                            />
                        </div>
                    </div>
                <DialogFooter>
                    <Button type="submit" disabled={false} onClick={submitUpdateProject}>Update</Button>
                </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
        <div className="">
          <div className="text-2xl font-bold text-center">SEQUENCES</div>
          <div className="py-4 text-center">
            <Button onClick={handleAddSequence} disabled={selectedProject === undefined} ><PlusSquare /> </Button>
          </div>
          {loadingSequences ? <Loading /> : projectSequences}
          <Dialog open={showSequenceForm} onOpenChange={(open)=>{ setShowSequenceForm(false); }}>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Create Sequence</DialogTitle>
                    <DialogDescription>This is a sequence.</DialogDescription>
                </DialogHeader>
                    <div className="grid gap-4 py-4">
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="title" className="text-right">
                            Title
                            </Label>
                            <Input
                                id="title"
                                name="title"
                                value={sequenceForm?.title}
                                onChange={handleInputChangeSequence}
                                className="col-span-3"
                            />
                        </div>
                        
                    </div>
                <DialogFooter>
                    <Button type="submit" disabled={false} onClick={submitAddSequence}>Submit</Button>
                </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
        <div className="">
          <div className="text-2xl font-bold text-center">BLOCKS</div>
        </div>
    </div>
    <Chatterface sequence={selectedSequence} />
  </div>
}

export interface DashboardPageProps {}

export const DashboardPage: React.FC<DashboardPageProps> = () => {
  const { userId }:any = useSessionContext();

  return (<Page>

      <div className="md:hidden">
        {/* <Image
          src="/examples/dashboard-light.png"
          width={1280}
          height={866}
          alt="Dashboard"
          className="block dark:hidden"
        />
        <Image
          src="/examples/dashboard-dark.png"
          width={1280}
          height={866}
          alt="Dashboard"
          className="hidden dark:block"
        /> */}
      </div>
      <WebsocketProvider uid={userId || "12345"}>
        <VideoGumData  />
      </WebsocketProvider>
      
      {/* <div className="hidden flex-col md:flex">
        <div className="flex-1 space-y-4 p-8 pt-6">
          <div className="flex items-center justify-between space-y-2">
            <h2 className="text-3xl font-bold tracking-tight">Dashboard</h2>
            <div className="flex items-center space-x-2">
              <CalendarDateRangePicker />
              <Button>Download</Button>
            </div>
          </div>
          <Tabs defaultValue="overview" className="space-y-4">
            <TabsList>
              <TabsTrigger value="overview">Overview</TabsTrigger>
              <TabsTrigger value="analytics" disabled>
                Analytics
              </TabsTrigger>
              <TabsTrigger value="reports" disabled>
                Reports
              </TabsTrigger>
              <TabsTrigger value="notifications" disabled>
                Notifications
              </TabsTrigger>
            </TabsList>
            <TabsContent value="overview" className="space-y-4">
              <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
                <Card>
                  <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                    <CardTitle className="text-sm font-medium">
                      Total Revenue
                    </CardTitle>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="h-4 w-4 text-muted-foreground"
                    >
                      <path d="M12 2v20M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" />
                    </svg>
                  </CardHeader>
                  <CardContent>
                    <div className="text-2xl font-bold">$45,231.89</div>
                    <p className="text-xs text-muted-foreground">
                      +20.1% from last month
                    </p>
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                    <CardTitle className="text-sm font-medium">
                      Subscriptions
                    </CardTitle>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="h-4 w-4 text-muted-foreground"
                    >
                      <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
                      <circle cx="9" cy="7" r="4" />
                      <path d="M22 21v-2a4 4 0 0 0-3-3.87M16 3.13a4 4 0 0 1 0 7.75" />
                    </svg>
                  </CardHeader>
                  <CardContent>
                    <div className="text-2xl font-bold">+2350</div>
                    <p className="text-xs text-muted-foreground">
                      +180.1% from last month
                    </p>
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                    <CardTitle className="text-sm font-medium">Sales</CardTitle>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="h-4 w-4 text-muted-foreground"
                    >
                      <rect width="20" height="14" x="2" y="5" rx="2" />
                      <path d="M2 10h20" />
                    </svg>
                  </CardHeader>
                  <CardContent>
                    <div className="text-2xl font-bold">+12,234</div>
                    <p className="text-xs text-muted-foreground">
                      +19% from last month
                    </p>
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                    <CardTitle className="text-sm font-medium">
                      Active Now
                    </CardTitle>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="h-4 w-4 text-muted-foreground"
                    >
                      <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
                    </svg>
                  </CardHeader>
                  <CardContent>
                    <div className="text-2xl font-bold">+573</div>
                    <p className="text-xs text-muted-foreground">
                      +201 since last hour
                    </p>
                  </CardContent>
                </Card>
              </div>
              <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-7">
                <Card className="col-span-4">
                  <CardHeader>
                    <CardTitle>Overview</CardTitle>
                  </CardHeader>
                  <CardContent className="pl-2">
                    <Overview />
                  </CardContent>
                </Card>
                <Card className="col-span-3">
                  <CardHeader>
                    <CardTitle>Recent Sales</CardTitle>
                    <CardDescription>
                      You made 265 sales this month.
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <RecentSales />
                  </CardContent>
                </Card>
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </div> */}
    </Page>
  )
}

export default DashboardPage
