
import React, {useEffect, useRef, useState, createContext, useContext} from 'react'
import { VG_SOCKET_URL } from '../lib/vg-client';

export interface WebsocketContextProps {
    ready: boolean;
    value: string | undefined;
    send: (data: string | Blob | ArrayBufferView | ArrayBufferLike) => void;
}
export const WebsocketContext = createContext<WebsocketContextProps>(
    {
        ready: false,
        value: undefined,
        send: (data)=>{},
    }
)

const MAX_RETRIES = 3;

// Make sure to put WebsocketProvider higher up in
// the component tree than any consumer.
export const WebsocketProvider = ({uid, children }: {uid:string, children: React.ReactNode}) => {
  const [isReady, setIsReady] = useState(false);
  const [val, setVal] = useState<string | undefined>(undefined);

  const ws = useRef<WebSocket | null>(null);

  useEffect(() => {
    let socket:WebSocket;
    let retries = 0;

    const tryConnect = ()=>{
        console.info(`trying to connect: ${VG_SOCKET_URL}`, uid, ws.current);
        if(ws.current){ console.log("already connected"); return; }

        socket = new WebSocket(`${VG_SOCKET_URL}/${uid}`)

        socket.onopen = () => {
            console.info("socket opened");
            setIsReady(true);
        }
        socket.onclose = () => {
            console.warn("socket closed");
            setIsReady(false);
            ws.current = null;
           
        }
        
        socket.onmessage = (event) => {
            console.info("socket message", event.data);

            if(event.data === "ping"){
                setTimeout(()=>{
                    socket.send("pong");
                },3000)
            }
            setVal(event.data);
        }
        socket.onerror = (err) => {
            console.error("socket error", err);
            console.log(retries, MAX_RETRIES)
            ws.current = null;
            
            if(retries > MAX_RETRIES){
                console.error("Max retries exceeded");
                return;
            }

            setTimeout(()=>{
                console.log("reconnecting");
                retries++;
                tryConnect();
            }, 3000)
        }
        ws.current = socket;
    }
 
    tryConnect()

    return () => {
        console.log("im' herees")
        if(socket){
            socket.close()
            ws.current = null;
        }
    }
  }, [uid])

  return (
    <WebsocketContext.Provider value={{
        ready: isReady,
        value: val,
        send: ws.current ? ws.current.send.bind(ws.current) : (data)=>{}
    }}>
      {children}
    </WebsocketContext.Provider>
  )
}



export const useWs = () => {
    return useContext(WebsocketContext);
}