import {
    VGProject,
    VGStudio,
    VGEntity,
    VGSequence,
    VGUser,
    VGDescriptiveSession,
    VGDescriptiveSessionAndMessages,
    VGDescriptiveSessionMessage
} from "../data/schema";
import {VG_ENV} from "../data/constants";


const VG_API_URL = window.location.origin.includes("localhost") ? window.location.origin.replace(":3000", ":9200") : `https://${VG_ENV}api.videogum.app`;
export const VG_SOCKET_URL = window.location.origin.includes("localhost") ? `ws://${window.location.host.replace(":3000", ":9200")}/ws` : `wss://${VG_ENV}api.videogum.app/ws`;

export interface VGAPIResponse<T> {
    success: boolean
    message?: string
    data?: T | null
}

export enum VGEndpoints {
    STUDIOS = "studios",
    PROJECTS = "projects",
    SEQUENCES = "sequences",
    THEMES = "themes" 
}

const API_ROOT= `${VG_API_URL}/api/v1`;

export const uploadFile = async (formData: FormData, onProgress: (value: number)=>void): Promise<VGAPIResponse<null>> => {
    console.log("VGClient uploadFile", VG_API_URL)
    console.log(formData)

    const req = new Request(`${API_ROOT}/assets/upload`, {
        //dont add this since the form already has it
        //headers: new Headers({ 'Content-Type': 'multipart/form-data' }),
        // auth uses cookies, so we don't need to send the token here
        method: 'POST',
        body: formData,
    });
    console.log("THE REQ", req)
    // Send the FormData object to the server using fetch or XMLHttpRequest

    const upldr = new Promise<VGAPIResponse<null>>((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.upload.addEventListener('progress', e => onProgress(e.loaded / e.total));
        xhr.addEventListener('load', () => resolve(JSON.parse(xhr.responseText) as VGAPIResponse<null>)); //{ status: xhr.status, body: xhr.responseText }
        xhr.addEventListener('error', () => reject(new Error('File upload failed')));
        xhr.addEventListener('abort', () => reject(new Error('File upload aborted')));
        xhr.open('POST', req.url, true);
        //const formData = new FormData();
        //Array.from(files).forEach((file, index) => formData.append(index.toString(), file));
        xhr.send(formData);
    })
    try {
        const resp = await upldr;
        return resp as VGAPIResponse<null>;
    } catch (e) {
        console.warn(e)
        throw new Error('An error occurred while uploading the file.');
    }

    // upload with fetch API
    // const resp = await fetch(req);
    // const jData = await resp.json() as VGAPIResponse<null>;
    // console.log("THE RESP", resp, " json ", jData)
    // if(resp.ok && resp.status >= 200 && resp.status < 300){
    //     console.log(resp)
    //     return jData;
    // } else {
    //     console.log(resp)
    //     throw new Error('An error occurred while uploading the file.');
    // }
}

export const getStudios = async (): Promise<VGAPIResponse<Array<VGStudio>>> => {
    console.log("VGClient getStudios", VG_API_URL)
    const resp = await fetch(`${API_ROOT}/studios`);
    const jData = await resp.json() as VGAPIResponse<Array<VGStudio>>;
    if(resp.ok && resp.status >= 200 && resp.status < 300){
        console.log(resp)
        return jData;
    } else {
        console.log(resp)
        throw new Error('An error occurred while getting the studios.');
    }
}

export const createStudio = async (studioForm: Partial<VGStudio>): Promise<VGAPIResponse<VGStudio>> => {
    console.log("VGClient creatStudio", VG_API_URL, studioForm)
    const req = new Request(`${API_ROOT}/studios`, {
        headers: new Headers({
            // 'Content-Type': 'multipart/form-data',
            'Content-Type': 'application/json'
        }),
        method: 'POST',
        body: JSON.stringify(studioForm),
    });
    const resp = await fetch(req);
    const jData = await resp.json() as VGAPIResponse<VGStudio>;
    if(resp.ok && resp.status >= 200 && resp.status < 300){
        console.log(resp)
        return jData;
    } else {
        console.log(resp)
        throw new Error('An error occurred while getting the studios.');
    }
}

export const deleteStudio = async (studioId: string): Promise<VGAPIResponse<null>> => {
    console.log("VGClient deleteStudio", VG_API_URL, studioId)
    const req = new Request(`${API_ROOT}/studios/${studioId}`, {
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
        method: 'DELETE'
    });
    const resp = await fetch(req);
    const jData = await resp.json() as VGAPIResponse<null>;
    if(resp.ok && resp.status >= 200 && resp.status < 300){
        console.log(resp)
        return jData;
    } else {
        console.log(resp)
        throw new Error('An error occurred while getting the studios.');
    }

}

export const getStudioProjects = async (studio_id: string): Promise<VGAPIResponse<Array<VGProject>>> => {
    console.log("VGClient getProjects", VG_API_URL)
    const resp = await fetch(`${API_ROOT}/studios/${studio_id}/projects`);
    const jData = await resp.json() as VGAPIResponse<Array<VGProject>>;
    if(resp.ok && resp.status >= 200 && resp.status < 300){
        console.log(resp)
        return jData;
    } else {
        console.log(resp)
        throw new Error('An error occurred while getting the studios.');
    }
}


export const getProjects = async (): Promise<VGAPIResponse<Array<VGProject>>> => {
    console.log("VGClient getProjects", VG_API_URL)
    const resp = await fetch(`${API_ROOT}/projects`);
    const jData = await resp.json() as VGAPIResponse<Array<VGProject>>;
    if(resp.ok && resp.status >= 200 && resp.status < 300){
        console.log(resp)
        return jData;
    } else {
        console.log(resp)
        throw new Error('An error occurred while getting the studios.');
    }
}

export const createProject = async (studioForm: Partial<VGProject>): Promise<VGAPIResponse<VGProject>> => {
    console.log("VGClient creatStudio", VG_API_URL, studioForm)
    const req = new Request(`${API_ROOT}/projects`, {
        headers: new Headers({
            // 'Content-Type': 'multipart/form-data',
            'Content-Type': 'application/json'
        }),
        method: 'POST',
        body: JSON.stringify(studioForm),
    });
    const resp = await fetch(req);
    const jData = await resp.json() as VGAPIResponse<VGProject>;
    if(resp.ok && resp.status >= 200 && resp.status < 300){
        console.log(resp)
        return jData;
    } else {
        console.log(resp)
        throw new Error('An error occurred while getting the studios.');
    }
}

export const updateProject = async (project_id: string, form: Partial<VGProject>): Promise<VGAPIResponse<VGProject>> => {
    console.log("VGClient updateStudio", VG_API_URL, form)
    const req = new Request(`${API_ROOT}/projects/${project_id}`, {
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
        method: 'PATCH',
        body: JSON.stringify(form),
    });
    const resp = await fetch(req);
    const jData = await resp.json() as VGAPIResponse<VGProject>;
    if(resp.ok && resp.status >= 200 && resp.status < 300){
        console.log(resp)
        return jData;
    } else {
        console.log(resp)
        throw new Error('An error occurred while getting the studios.');
    }
}

export const deleteProject = async (project_id: string): Promise<VGAPIResponse<null>> => {
    console.log("VGClient deleteProject", VG_API_URL, project_id)
    const req = new Request(`${API_ROOT}/projects/${project_id}`, {
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
        method: 'DELETE'
    });
    const resp = await fetch(req);
    const jData = await resp.json() as VGAPIResponse<null>;
    if(resp.ok && resp.status >= 200 && resp.status < 300){
        console.log(resp)
        return jData;
    } else {
        console.warn(resp)
        throw new Error('An error occurred while getting the studios.');
    }

}

const buildQueries = (params: Record<string, string>): string => {
    const queries = Object.entries(params).map(([key, val]) => `${key}=${val}`).join("&")
    return queries;
}

export const getCollection = async <T>(collection: string, queries?:Record<string, string>): Promise<VGAPIResponse<Array<T>>> => { 
    const resp = await fetch(`${API_ROOT}/${collection}${queries ? `?${buildQueries(queries)}`: ""}`,{
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
        method: 'GET'
    })
    const dat = await resp.json() as VGAPIResponse<Array<T>>        
    return dat;
}

export const getStudioProjectSequences = async (studio_id: string, project_id: string): Promise<VGAPIResponse<Array<VGSequence>>> => {
    console.log("VGClient getProjects", VG_API_URL)
    const resp = await fetch(`${API_ROOT}/studios/${studio_id}/projects/${project_id}/sequences`);
    const jData = await resp.json() as VGAPIResponse<Array<VGSequence>>;
    if(resp.ok && resp.status >= 200 && resp.status < 300){
        console.log(resp)
        return jData;
    } else {
        console.log(resp)
        throw new Error('An error occurred while getting the studios.');
    }
}

export const createSequence = async (sequence: Partial<VGSequence>): Promise<VGAPIResponse<VGSequence>> => {
    const resp = await fetch(`${API_ROOT}/sequences`,{
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
        method: 'POST',
        body: JSON.stringify(sequence)
    })
    const dat = await resp.json() as VGAPIResponse<VGSequence>        
    return dat;
}

export const updateSequence = async (id: string, sequence: Partial<VGSequence>): Promise<VGAPIResponse<VGSequence>> => {
    const reps = await fetch(`${API_ROOT}/sequences/${id}`,{
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
        method: 'PATCH',
        body: JSON.stringify(sequence)
    })
    const dat = await reps.json() as VGAPIResponse<VGSequence>
    return dat;
}

export const getConversation = async (id: string): Promise<VGAPIResponse<VGDescriptiveSession>> => {
    const resp = await fetch(`${API_ROOT}/jtai/descriptive_sessions/${id}`,{
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
        method: 'GET'
    })
    const dat = await resp.json() as VGAPIResponse<VGDescriptiveSession>        
    return dat;
}

export const getConvoMessages = async (id: string, thread_id: string): Promise<VGAPIResponse<Array<VGDescriptiveSessionMessage>>> => {
    const resp = await fetch(`${API_ROOT}/jtai/descriptive_sessions/${id}/threads/${thread_id}/messages`,{
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
        method: 'GET'
    })
    const dat = await resp.json() as VGAPIResponse<Array<VGDescriptiveSessionMessage>>
    return dat;
}

export const getConvoImage = async (id: string): Promise<VGAPIResponse<string>> => {
    const resp = await fetch(`${API_ROOT}/jtai/descriptive_sessions/files/${id}/content`,{
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
        method: 'GET'
    })
    const dat = await resp.json() as VGAPIResponse<string>
    return dat;
}

export const createGenImage = async (prompt: string): Promise<VGAPIResponse<string>> => {
    const resp = await fetch(`${API_ROOT}/jtai/gen`,{
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
        method: 'POST',
        body: JSON.stringify({prompt})
    })
    const dat = await resp.json() as VGAPIResponse<string>
    return dat;
}


export const startConversation = async (sequenceId: string): Promise<VGAPIResponse<VGDescriptiveSessionAndMessages>> => {
    const resp = await fetch(`${API_ROOT}/jtai/descriptive_sessions`,{
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
        method: 'POST',
        body: JSON.stringify({sequence_id: sequenceId})
    })
    const dat = await resp.json() as VGAPIResponse<VGDescriptiveSessionAndMessages>
    return dat;
}

export const continueConversation = async (convo: VGDescriptiveSession, userTxt: string): Promise<VGAPIResponse<Array<VGDescriptiveSessionMessage>>> => {
    const resp = await fetch(`${API_ROOT}/jtai/descriptive_sessions/${convo.id}`,{
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
        method: 'PATCH',
        body: JSON.stringify({...convo, user_input: userTxt})
    })
    const dat = await resp.json() as VGAPIResponse<Array<VGDescriptiveSessionMessage>>
    return dat;
}

export const removeConversation = async (convo: VGDescriptiveSession): Promise<VGAPIResponse<null>> => {
    const resp = await fetch(`${API_ROOT}/jtai/descriptive_sessions/${convo.id}`,{
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
        method: 'DELETE',
    })
    const dat = await resp.json() as VGAPIResponse<null>        
    return dat;
}


export const getUser = async (id: string): Promise<VGAPIResponse<VGUser>> => {
    const resp = await fetch(`${API_ROOT}/users/${id}`,{
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
        method: 'GET'
    })
    const dat = await resp.json() as VGAPIResponse<VGUser>
    return dat;
}

export const updateUser = async (userId: string, user: Partial<VGUser>): Promise<VGAPIResponse<null>> => {
    const resp = await fetch(`${API_ROOT}/users/${userId}`,{
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
        method: 'PATCH',
        body: JSON.stringify(user)
    })
    const dat = await resp.json() as VGAPIResponse<null>
    return dat;
}